import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="The confidence behind a smile makeover | The Smile Stylist Blog" 
    description=" 'How will your life change after a smile makeover? Find out about the power and confidence involved in getting your very own natural looking porcelain veneers." 
    keywords="Smile Makeover" />
    <main className="site-content prindex" role="main">
    <section id="main-top-img" className="blog hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <div className="blog-news-art">
              <h1>The confidence behind a smile makeover</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <p>Humankind is one that is deeply dependent on subconscious needs. This could be the more classic need to be fed, or have a drink, or for the sake of this article, the need to have self-esteem. Self-esteem is essential for a healthy relationship with one's self, and no matter if you are the most outgoing person in the world, the lack of a healthy and great smile can greatly affect this. In this article we are going to look at how loving your smile after a smile makeover and feeling confident about it will be able to transform your life.</p>
            <img src={ require("../../images/desktop/Oct 11 - New Before After.png" )} alt="Oct 11 - New Before After" className="bl__img" />
            <h3>Flash your smile</h3>
            <p>As we noted, self-esteem is one of the crucial ways in which humans value their relationship with their selves. However, self-esteem also is a vital ingredient for a successful relationship with others. You see, humans want to gain acceptance by others, this could be in a group, or with another individual, but we all want to achieve that critical ability to be liked. So you might be asking yourself, well that sounds good, but what does this have to do with a smile? Well, the answer is quite simple, the way you express unconditional acceptance of a group or person is with a smile. A smile is the universal language of acceptance, and with a smile makeover, you can flash it when you need to, and in any social circle.</p>
            <img src={ require("../../images/desktop/Margaret Before After.png" )} alt="Margaret Before After" className="bl__img" />
            <h3>Wear a smile</h3>
            <p>The act of smiling has been proven by double-blind studies to in fact improve your mood throughout the day, that is the power of this simple action. When you offer a smile to someone in your life, whether that be your partner or co-worker, you are telling that person subconsciously that you feel safe and secure with their relationship and that you like them. A simple smile and saying hello to your cubicle mate will go a long way in ensuring that you have a great relationship, and wearing a smile is a great way to get noticed in the office. The power of a smile is fantastic, and with a smile makeover, you will be able to wear it with pride daily.</p>
            <img src={ require("../../images/desktop/Styling your smile 2.png" )} alt="Styling your smile 2" className="bl__img" />
            <h3>The Payoff</h3>
            <p>A smile makeover will do more than improve your smile; it is going to give you the confidence and aura to change your life. In the workplace, your smile will start to help you secure and close deals that before were a challenge. You will finally be able to break down the barriers with that pesky boss and secure that next step in the corporate ladder. With the power of your smile, you will be able to talk to clients candidly and help them succeed with your company. While at home, your relationship with your partner and family will benefit from your smile. You will enjoy a closer relationship with your better half, as you will finally be able to communicate through non-verbal clues. Your kids will be greeted with a smile in the morning and start their day off right. The possibilities are endless, and it all comes down to gaining the confidence to smile again after a smile makeover.</p>
            <p>Now is the time to call us to receive your smile makeover information. We have been completing these life-changing procedures for years, and no matter if it’s a single dad or a teenager, our team will be able to transform your life truly. See how you can become a new and powerful person with your smile makeover today!</p>
          </div>
          <div className="col-sm-4 blog-right">
            <a href="/virtual-smile-makeover"><img src={ require("../../images/desktop/get-virtual-smile.png" )} className="img-responsive wow animated zoomIn" data-wow-delay="0.3s" /></a>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage